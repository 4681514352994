dmx.Component('google-distance-matrix', {

    /*
        result object
        {
            origin: String,
            destination: String,
            distance: String,
            duration: String,
            meters: Number,
            seconds: Number
        }
    */

    initialData: {
        status: '',
        results: []
    },

    attributes: {
        origins: {
            type: [Array, String],
            default: null
        },

        destinations: {
            type: [Array, String],
            default: null
        },

        'travel-mode': { // DRIVING, TRANSIT, WALKING, BICYCLING
            type: String,
            default: 'DRIVING'
        },

        units: { // METRIC, IMPERIAL
            type: String,
            default: 'METRIC'
        },

        'avoid-highways': {
            type: Boolean,
            default: false
        },

        'avoid-tolls': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        getDistance: function(origin, destination) {
            this.getDistance(origin, destination);
        }
    },

    render: function(node) {
        this.service = new google.maps.DistanceMatrixService();
        this.update({});
    },

    update: function(props) {
        if (this.props.origins != props.origins || this.props.destinations != props.destinations) {
            this.getDistance(this.props.origins, this.props.destinations);
        }
    },

    getDistance: function(origins, destinations) {
        if (!(origins && destinations)) return;

        if (typeof origins == 'string') {
            origins = origins.split(/\s*\|\/*/);
        }

        if (typeof destinations == 'string') {
            destinations = destinations.split(/\s*\|\/*/);
        }

        this.service.getDistanceMatrix({
            origins: origins,
            destinations: destinations,
            travelMode: this.props['travel-mode'],
            unitSystem: google.maps.UnitSystem[this.props.units],
            avoidHighways: this.props['avoid-highways'],
            avoidTolls: this.props['avoid-tolls']
        }, this.onResponse.bind(this));
    },

    onResponse: function(response, status) {
        this.set('status', status);

        if (status == 'OK') {
            var results = [];
            var origins = response.originAddresses;
            var destinations = response.destinationAddresses;

            for (var i = 0; i < origins.length; i++) {
                var elements = response.rows[i].elements;

                for (var j = 0; j < elements.length; j++) {
                    var element = elements[j];

                    results.push({
                        origin: origins[i],
                        destination: destinations[j],
                        distance: element.distance.text,
                        duration: element.duration.text,
                        meters: element.distance.value,
                        seconds: element.duration.value
                    });
                }
            }

            this.set('results', results);
        }
    }

});
